import React, { Component } from "react";
import Slider from "react-slick";
import Card from 'react-bootstrap/Card';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PauseOnHover.css";

export default class Achievement extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
        // button: 'black', }


        return (
            <div style={{ margin: "30px 0px" }}>
                <Slider {...settings} variant="dark" className="d-flex align-items-center justify-content-center" >
                    <div className="d-flex align-items-center justify-content-center" >
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src='https://i.postimg.cc/yYDmBc6J/Mr-Sahil-Jivan-Kothekar-3-png.webp' style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Mr.Sahil Jivan Kothekar</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Mr.Sahil jivan kothekar got 9.47 cgpa
                                    He was 2nd merit in year 2019-2022 from university in Mechanical branch also recieved Mr.Nilesh Mahakalkar prize of RTMNU
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src="https://i.postimg.cc/vBG7dKDM/Mr-Devashish-Sontakke-3-png.webp" style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Mr.Devashish Sontakke</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Mr.Devashish Sonatake has done Engineering Analysis and Design from Indian Institute of Technology,Delhi
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src='https://i.postimg.cc/63HZx5DL/Mr-Swanand-Jugade-3-png.webp' style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Mr.Swanand Jugade</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Mr.Swanand Jugade  has done Aerospace Engineering from College of Engineering and Applied Sciences, University of Cincinnati,Ohio ,US
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src="https://i.postimg.cc/mrfNmksP/Best-department-award-png.webp" style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Best Department Award</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src="https://i.postimg.cc/2SB7dvPp/Dhiraj-Nichant-png.webp" style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Mr.Dhiraj Nichant</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Mr.Dhiraj Nichant a final year student recieved Best Outgoing Student (Sports) Award in year 2019-2020.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src='https://i.postimg.cc/s2YPqzjw/Mr-Aayush-Ulhas-Deshmukh-3-png.webp' style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Mr.Ayush Ulhas Deshmukh</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Mr.Ayush Ulhas Deshmukh has done  master of engineering (M.Eng) in Automotive Engineering from University of Michigan
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src="https://i.postimg.cc/ZnCdbzmk/MRAnkit-Jamaiwar-png.webp" style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Mr.Ankit Jamaiwar</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Mr.Ankit Jamaiwar a third year student participated in Static Aeromodelling Competition and won Bronze medal
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src='https://i.postimg.cc/Zn2y0sYW/Ms-Akshita-Sukesh-Baghel-3-png.webp' style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Ms Akshita Sukesh Baghel</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Ms Akshita Sukesh Baghel has done MSE in Automotive system engineering / mechanical engineering from University of Michigan
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <Card style={{ minHeight: "440px", width: '500px', backgroundColor: 'rgb(57 62 70)', color: "#eeeeee", borderRadius: "20px", border: '1.3mm ridge rgb(34 40 49)' }} >
                            <Card.Img variant="top" src='https://i.postimg.cc/K8XM2WsV/snheal-jpg.webp' style={{ height: "300px", borderRadius: "20px 20px 0 0" }} />
                            <Card.Body style={{ height: "210px" }}>
                                <Card.Title style={{ fontSize: "18px" }}>Ms Snheal joshi</Card.Title>
                                <Card.Text style={{ fontSize: "15px" }}>
                                    Ms Snehal Sunil Joshi
                                    Triathlete
                                    Represented India at Asian Triathlon Championship Nepal ,
                                    Asian Triathlon Cup,  Malaysia
                                    Won Silver medal at Asain Duathlon open.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Slider>
            </div>



        );
    }
}

